<template>
  <a-select
    allowClear
    style="width: 100%"
    :disabled="disabled"
    v-model="selectedValue"
    :placeholder="selfPlaceholder"
    :not-found-content="loading ? undefined : null"
    @focus="getInvoiceTypeList"
    @change="selectHandle"
  >
    <a-spin v-if="loading" slot="notFoundContent" size="small" />
    <a-select-option v-for="item in invoiceTypeList" :value="item.code" :key="item.code">
      {{ item.name }}
    </a-select-option>
  </a-select>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['placeholder', 'disabled', 'code'],
  data() {
    return {
      invoiceTypeList: [],
      loading: false,
      selectedValue: undefined,
    }
  },
  computed: {
    ...mapState(['invoice_type']),
    selfPlaceholder() {
      return this.placeholder || this.$t('发票类型')
    },
  },
  watch: {
    code: {
      immediate: true,
      handler(val) {
        if (val) {
          setTimeout(() => {
            this.getInvoiceTypeList()
            this.setDefault()
          })
        }
      },
    },
  },
  methods: {
    clear() {
      this.selectedValue = undefined
    },
    async getInvoiceTypeList() {
      if (this.invoiceTypeList.length) return
      this.invoiceTypeList = this.invoice_type
      this.setDefault()
    },
    setDefault() {
      if (this.code) {
        this.selectHandle(this.code)
      }
    },
    selectHandle(val) {
      this.selectedValue = val
      if (val) {
        let data = this.invoiceTypeList.find((item) => item.code === val)
        this.$emit('selected', data)
        this.$emit('update:code', val)
      }
    },
  },
}
</script>
